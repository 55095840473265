* {
  box-sizing: border-box;
}

body {
  color: #474748;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  scroll-behavior: smooth;
}

main {
  margin-top: 100px; /* navbar height */
  min-height: calc(100vh - 440px);
}

h2 {
  font-size: 40px;
  font-weight: inherit;
  line-height: 2rem;
}

h3 {
  font-size: 30px;
  font-weight: inherit;
  line-height: 2rem;
}

h4 {
  font-size: 20px;
  font-weight: inherit;
  line-height: 2rem;
}

h5 {
  color: gray;
  font-size: 22px;
  font-weight: inherit;
  margin-bottom: 15px;
}

p {
  font-size: 17px;
  line-height: 2rem;
}

.title {
  font-size: 40px;
  font-weight: bold;
  color: #686868;
  font-family: "Montserrat", sans-serif;
  text-align: center;
}

.wrapper {
  width: min(90%, 1200px);
  margin: auto !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}