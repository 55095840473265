.nav-links {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .nav-links li {
    margin: 1rem;
  }
  
  .nav-links a {
    border: 1px solid transparent;
    color: #292929;
    text-decoration: none;
    padding: 0.5rem;
  }
  
  .nav-links a:hover {
    background: #f3af47;
  }
  .nav-links a:active,
  .nav-links a.active {
    background: #f3ce61;
    color: #292929;
  }
  
  .nav-links button {
    cursor: pointer;
    border: 1px solid #292929;
    color: #292929;
    background: transparent;
    padding: 0.5rem;
    font: inherit;
  }
  
  .nav-links button:focus {
    outline: none;
  }
  
  .nav-links button:hover,
  .nav-links button:active {
    background: #de8d0f;/*#292929;*/
    color: white;
  }
  
  @media (min-width: 768px) {
    .nav-links {
      flex-direction: row;
    }
  
    .nav-links li {
      margin: 0 0.5rem;
    }
  
    .nav-links a {
      color: white;
      text-decoration: none;
    }
  
    .nav-links button {
      border: 1px solid white;
      color: white;
      background: #ccc;
    }
    
    .nav-links button:hover,
    .nav-links button:active {
      background: #de8d0f;
      color: #292929;
    }
  }

  .iconImg {
    width: 30px;
    height: 30px;
  }


  .icon{ 
    margin-left: 5px;
    cursor: pointer;
    position: relative;
  }

  .counter {
    width: 18px;
    height: 18px;
    background-color: red;
    border-radius: 50%;
    padding: 5px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -8px;
    right: -3px
  }

  .notifications{
    position: absolute;
    top: 40px;
    right: 0px;
    background-color: white;
    color: black;
    font-weight: 100;
    display: flex;
    flex-direction: column;
    padding: 10px;

  }

  .notification{
    padding: 5px;
    font-size: 14px;
  }

  .seen {
    background-color: #f3ce61;
  }

  .wrapper {
    width: 150px;
    margin: 10px auto 0;
    background: transparent;
  }

  .select-btn {
    height: 20px;
    font-size: 14px;
    display: flex;
    cursor: pointer;
    padding: 0 8px;
    border-radius: 2px;
    background: transparent;
    color: white;
  }

  .content {
    display: block;
    padding: 5px;

    margin-top: 5px;
    border-radius: 3px;
    background: #f3ce61;
  }

  .content .options {
    margin-top: 5px;
    max-height: 20px;
    overflow-y: auto;
  }

  .options::-webkit-scrollbar {
    width: 10px;
  }

  .options::-webkit-scrollbar-track{
    background: #f1f1f1;
    border-radius: 10px;
  }

  .options::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 1px;
  }

  .options option {
    height: 15px;
    width: 60px;
    font-size: 14px;
    cursor: pointer;
  }
  .option:hover,
  .option:active {
    background: #1882A8;
  }